<script setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {computed, onMounted, ref} from "vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {ElLoading} from "element-plus";
import {useForm} from "@inertiajs/inertia-vue3";
import Modal from "@/Components/Modal.vue";
import AgentCarrierDataForm from "@/Components/partials/AgentCarrierDataForm.vue";

const props = defineProps({
  modelType: {
    type: String,
    required: true,
    validator(value, props) {
      return ['agent', 'lead'].includes(value);
    },
  },
  modelId: {
    type: Number,
    required: true,
  },
  agencyCarriers: {
    type: Object,
  }
});

const isAgentModel = computed(() => { // Default
  return !props.modelType || props.modelType !== 'lead';
});
const isLeadModel = computed(() => { // Default
  return props.modelType === 'lead';
});

const formData = ref({
  carrier_id: null,
});
const assignedCarriers = ref([]);
const FMOs = ref([]);
const loading = ref(false);
const globalLoading = ref(false);
const showCreateModal = ref(false);
const errors = ref({});

onMounted(() => {
  fetchModelCarriers()
  fetchFMOs()
})

function fetchModelCarriers() {
  loading.value = true;
  let target = isAgentModel.value ? `/agents/${props.modelId}/carriers` : `/leads/${props.modelId}/carriers`;
  axios
  .get(target)
  .then((response) => {
    assignedCarriers.value = response.data;
  })
  .catch((error) => {
    // Handle error
  })
  .finally(() => {
    loading.value = false;
  })
}

function fetchFMOs() {
  axios
  .get(`/mgas`)
  .then((response) => {
    FMOs.value = response.data;
  })
  .catch((error) => {
    // Handle error
  })
  .finally(() => {
    // loading.value = false;
  })
}

function assignCarrier(carrier, elementId, e) {
  if(e.target.value == 0) {
    return;
  }

  loading.value = true;
  setTimeout(() => {
    let target = isAgentModel.value ? `/agents/${props.modelId}/carriers` : `/leads/${props.modelId}/carriers`;
    axios
    .post(target, {
      agency_carrier_id: carrier.agency_carrier_id,
      mga_id: carrier.mga_id,
    })
    .then((response) => {
      let element = document.getElementById(elementId);
      if (element) {
        element.value = 'unassigned';
      }
      assignedCarriers.value.push(response.data);
    })
    .catch((error) => {
      handleErroredSubmission(error);
    })
    .finally(() => {
      loading.value = false;
    })
  }, 500)
}

function removeAssignedCarrier(carrier, elementId, e) {
  if(e.target.value == 1) {
    return;
  }

  loading.value = true;
  setTimeout(() => {
    let target = isAgentModel.value ? `/agents/${props.modelId}/carriers/${carrier.agency_carrier_id}` : `/leads/${props.modelId}/carriers/${carrier.agency_carrier_id}`;
    axios
    .delete(target)
    .then((response) => {
      if (response.status === 202) {
        // Accepted
        let element = document.getElementById(elementId);
        if (element) {
          element.value = 'assigned';
        }
        assignedCarriers.value = assignedCarriers.value.filter(i => i.agency_carrier_id !== carrier.agency_carrier_id);
      }
      if (response.status === 200 && response.data?.message) {
        setTimeout(() => {
          e.target.value = '1';
        }, 50);
        $notify.warning({message: response.data.message});
      }
    })
    .catch((error) => {
      handleErroredSubmission(error);
    })
    .finally(() => {
      loading.value = false;
    })
  }, 500)
}

function changeMga(carrier, e) {
  loading.value = true;
  setTimeout(() => {
    let target = isAgentModel.value ? `/agents/${props.modelId}/carriers/${carrier.agency_carrier_id}` : `/leads/${props.modelId}/carriers/${carrier.agency_carrier_id}`;
    axios
    .patch(target, {
      mga_id: e.target.value
    })
    .then((response) => {
      // Handle the response
    })
    .catch((error) => {
      handleErroredSubmission(error);
    })
    .finally(() => {
      loading.value = false;
    })
  }, 500)
}

function confirmRemove(agency_carrier_id) {
  Swal.fire({
    title: 'Are you sure?',
    icon: 'warning',
    confirmButtonText: "Yes, I'm sure.",
    showCancelButton: true,
    confirmButtonColor: '#B91C1C',
    cancelButtonColor: '#899CC1'
  }).then((result) => {
    if (result.isConfirmed) {
      removeAssignedCarrier(agency_carrier_id);
    }
  })
}

function handleErroredSubmission(error) {
  if (error.response && error.response.data && error.response.status === 422) {
    errors.value = error.response.data.errors;
  } else {
    Swal.fire({
      text: 'Unknown error occurred, please try again.',
      icon: 'error'
    })
  }
  console.log("AgentAndLeadCarriers - handleErroredSubmission", error.response);
}

function isInvalid(attribute) {
  return errors.value.hasOwnProperty(attribute);
}

function errorMessage(attribute) {
  if (errors.value.hasOwnProperty(attribute)) {
    return errors.value[attribute][0];
  }
}

const modelCarrierIds = computed(() => {
    return assignedCarriers.value.map(i => i.carrier_id);
})

const availableCarriers = computed(() => {
    return props.agencyCarriers.filter(i => !modelCarrierIds.value.includes(i.carrier_id)).map((i) => ({
        ...i,
        assigned: 0,
        mga_id: i.mga_id ? i.mga_id : null,
      })
    );
})

const agentCarrierDataModalVisible = ref(false);
const agentCarrierFormUplineOptions = ref([]);
const agentCarrierFormData = useForm({
  status: null,
  writing_number: null,
  recruiting_number: null,
  commission: null,
  effective_date: null,
  notes: null,
  direct_upline: null,
  dnc: null,
  carrier_id: null,
  contracting_agency_carrier_agent_leader_id: null,
});
const hideAgentCarrierDataModal = function() {
  agentCarrierDataModalVisible.value = false;
  agentCarrierFormUplineOptions.value = [];
  agentCarrierFormData.reset();
}
const openAgentCarrierDataModal = function(carrier) {
  loading.value = true;
  agentCarrierFormData.reset();
  axios
    .get(route('agent.agent-carrier.data', {
      agent: props.agentId,
      carrier: carrier.carrier_id,
    }))
    .then(r => {
      if (!r || !r.data) {
        $notify.error({message: 'Could not load data'});
      }
      agentCarrierFormUplineOptions.value = r.data.upline_options;
      agentCarrierFormData.carrier_id = carrier.carrier_id;
      agentCarrierFormData.status = r.data.status;
      agentCarrierFormData.writing_number = r.data.writing_number;
      agentCarrierFormData.recruiting_number = r.data.recruiting_number;
      agentCarrierFormData.commission = r.data.commission;
      agentCarrierFormData.effective_date = r.data.effective_date;
      agentCarrierFormData.notes = r.data.notes;
      agentCarrierFormData.dnc = r.data.dnc;
      agentCarrierFormData.contracting_agency_carrier_agent_leader_id = r.data.contracting_agency_carrier_agent_leader_id;
      agentCarrierDataModalVisible.value = true;
    })
    .catch(e => {
      throw e;
    })
    .finally(() => {
      loading.value = false;
    })
}

const submitAgentCarrierData = function() {
  loading.value = true;
  agentCarrierFormData.put(route('agent.agent-carrier.data.update', {
    agent: props.agentId,
    carrier: agentCarrierFormData.carrier_id,
  }), {
    onSuccess: (r) => {
      $notify.success({message: 'Agent / Carrier data updated'});
      hideAgentCarrierDataModal();
    },
    onError: (e) => {
      console.log(e);
    },
    onFinish: () => {
      loading.value = false;
    }
  });
}

function confirmAssignAllCarriers() {
    Swal.fire({
        title: 'Are you sure?',
        text: 'This will assign all carriers with the default FMO.',
        icon: 'warning',
        confirmButtonText: "Yes, I'm sure.",
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
    }).then((result) => {
        if (result.isConfirmed) {
            assignAllCarriers();
        }
    })
}
function assignAllCarriers() {
    loading.value = true;
    setTimeout(() => {
        let target = isAgentModel.value ? `/agents/${props.modelId}/carriers/assign-all` : `/leads/${props.modelId}/carriers/assign-all`;
        axios
            .post(target)
            .then((response) => {
                if (response.status === 200) {
                    $notify.success({message: 'All carriers have been assigned.'});
                    setTimeout(() => window.location.reload(), 500);
                }
            })
            .catch((error) => {
                handleErroredSubmission(error);
            })
            .finally(() => {
                loading.value = false;
            })
    }, 500)
}
</script>

<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        Carriers
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button @click="showCreateModal = true" type="button"
                class="btn-primary inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm sm:w-auto">
          Assign new carrier
        </button>
      </div>
    </div>
    <div class="mt-8 flex flex-col" v-if="assignedCarriers.length">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Carrier</th>
                <th class="py-3.5 pl-4 text-left text-sm font-semibold text-right text-gray-900 sm:pr-6">
                  Actions
                  <span class="sr-only">Actions</span>
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="carrier in assignedCarriers">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">{{ carrier.name }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                    <button @click.prevent="confirmRemove(carrier.agency_carrier_id)" class="text-sm btn-primary">
                      Remove
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-full text-center my-4">
      No carriers.
    </div> -->


    <div class="flex space-x-2 justify-between">
      <h3 class="font-medium">Assigned Carriers</h3>
      <button class="btn btn-primary btn-sm text-sm" @click.prevent="confirmAssignAllCarriers"
        :disabled="loading" :class="{'opacity-30': loading}"
      >Assign All</button>
    </div>
    <div class="mt-2 flex flex-col mb-8">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Carrier</th>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">FMO</th>
                <th class="py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pr-6">
                  Access
                </th>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Data</th>
              </tr>
              </thead>
              <tbody v-if="assignedCarriers.length" class="divide-y divide-gray-200 bg-white">
                <tr v-for="(carrier, index) in assignedCarriers" :key="`${index}_${carrier.id}`" :class="{'bg-green-100': assignedCarriers.length > 0}">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">{{ carrier.name }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                    <select class="input-field" v-model="carrier.mga_id" @change="e => changeMga(carrier, e)" :disabled="loading">
                      <option key="null" value="" selected>Select a FMO</option>
                      <option v-for="item in FMOs" :key="item.id" :value="item.id">{{item.agency_name}} - {{item.agency_code}}</option>
                    </select>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                    <select class="input-field" v-model="carrier.assigned" :id="`assignedselect_${index}`" @change.prevent="e => removeAssignedCarrier(carrier, `assignedselect_${index}`, e)" :disabled="loading">
                      <option key="assigned" :value="1" disabled>Assigned</option>
                      <option key="unassign" :value="0" >Unassign</option>
                    </select>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                    <button class="btn btn-primary" @click="openAgentCarrierDataModal(carrier)">View</button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else class="divide-y divide-gray-200 bg-white">
                <tr>
                  <td colspan="6" class="whitespace-nowrap px-3 py-4 text-sm text-gray-600 text-center">There are no more available carriers for this {{ modelType }}.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <h3 class="font-medium mt-8">Unassigned Carriers</h3>
    <div class="mt-2 flex flex-col mb-8">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Carrier</th>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">FMO</th>
                <th class="py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pr-6">
                  Access
                </th>
              </tr>
              </thead>
              <tbody v-if="availableCarriers.length" class="divide-y divide-gray-200 bg-white">
                <tr v-for="(carrier, index) in availableCarriers" :key="index">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">{{ carrier.name }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                    <select class="input-field" v-model="carrier.mga_id" :disabled="loading">
                      <option :value="null" selected>Select a FMO</option>
                      <option v-for="item in FMOs" :key="item.id" :value="item.id">{{item.agency_name}} - {{item.agency_code}}</option>
                    </select>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                    <select class="input-field" v-model="carrier.assigned" :id="`unassignedselect_${index}`" @change="e => assignCarrier(carrier, `unassignedselect_${index}`, e)" :disabled="loading">
                      <option key="unassigned" :value="0" disabled>Unassigned</option>
                      <option key="assign" :value="1">Assign</option>
                    </select>
                  </td>
                </tr>
              </tbody>
              <tbody v-else class="divide-y divide-gray-200 bg-white">
                <tr>
                  <td colspan="6" class="whitespace-nowrap px-3 py-4 text-sm text-gray-600 text-center">There are no more available carriers for this {{ modelType }}.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- BEGIN: Assign Carriers Modal -->
  <TransitionRoot as="template" :show="showCreateModal">
    <Dialog as="div" class="relative z-10" @close="showCreateModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mt-3 sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">Assign new
                    carrier
                  </DialogTitle>
                  <div>
                    <form @submit.prevent="assignCarrier" class="intro-y box p-5" method="POST">
                      <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
                        <!-- BEGIN: Carrier -->
                        <div class="col-span-12 input-form" :class="{'has-error': isInvalid('agency_carrier_id')}">
                          <label for="bucket_id" class="form-label text-gray-600">Carrier</label>
                          <select class="input-field" name="agency_carrier_id" id="carrier_id" v-model="formData.agency_carrier_id">
                            <option value="">—</option>
                            <option v-for="carrier in availableCarriers" :key="carrier.agency_carrier_id" :value="carrier.agency_carrier_id">{{ carrier.name }}</option>
                          </select>
                          <div v-if="isInvalid('agency_carrier_id')" class="error-message mt-1">{{ errorMessage('agency_carrier_id') }}</div>
                        </div>
                        <!-- END: Carrier -->
                      </div>

                      <div class="text-right mt-5">
                        <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
                          <LoadingSpinner v-if="loading"></LoadingSpinner>
                          <span v-else>Submit</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <Modal :show="agentCarrierDataModalVisible" @onClose="hideAgentCarrierDataModal" title="Update Agent/Carrier Data">
    <AgentCarrierDataForm
      :agent-id="agentId"
      :carrier-id="agentCarrierFormData.carrier_id"
      :form-data="agentCarrierFormData"
      :loading="loading"
      :upline-options="agentCarrierFormUplineOptions"
      @cancel="hideAgentCarrierDataModal"
      @submit="submitAgentCarrierData"
    ></AgentCarrierDataForm>
  </Modal>

</template>

<style scoped>

</style>
