<script setup>
import {Link} from "@inertiajs/inertia-vue3";
import {computed, ref} from "vue";
import {ElSwitch} from "element-plus";
import {Inertia} from "@inertiajs/inertia";
import _ from 'lodash';

const props = defineProps({
  lead: Object,
  leadCampaigns: {},
  leadCampaignsGlobalExclusion: {},
});

const campaignsEnabled = computed(() => ! Boolean(props.leadCampaignsGlobalExclusion));

const refetchOnly = ['leadCampaigns', 'leadCampaignsGlobalExclusion'];

const toggleCampaignEnabled = function(){
    let currVal = campaignsEnabled.value;
    let newVal = !currVal;
    if (newVal) {
        enableCampaignsGlobally();
    } else {
        disableCampaignsGlobally();
    }
};
const enableCampaignsGlobally = function(){
    Swal.fire({
        title: 'Are you sure?',
        text: 'This will enable campaigns for this lead',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, enable campaigns',
        cancelButtonText: 'No, cancel',
    }).then((result) => {
        if (result.isConfirmed) {
            Inertia.post(route('lead-campaigns.global-enable', props.lead.id), {
                only: refetchOnly,
                onSuccess: () => {
                    $notify.success('Campaigns enabled for this lead');;
                },
            });
        }
    })
};
const disableCampaignsGlobally = function(){
    Swal.fire({
        title: 'Are you sure?',
        text: 'This will disable all campaigns for this lead',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, disable campaigns',
        cancelButtonText: 'No, cancel',
    }).then((result) => {
        if (result.isConfirmed) {
            Inertia.post(route('lead-campaigns.global-disable', props.lead.id), {
                only: refetchOnly,
                onSuccess: () => {
                    $notify.success('Campaigns disabled for this lead');
                },
            });
        }
    })
};

const getCampaignRoute = function(campaign){
    if (campaign.type === 'email') {
        return route('email-campaign.details.show', campaign.id);
    }
    if (campaign.type === 'text') {
        return route('text-campaign.details.show', campaign.id);
    }
};
const removeCampaign = function(campaignId){
    Swal.fire({
        title: 'Are you sure?',
        text: 'This will remove this lead from the campaign',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove from campaign',
        cancelButtonText: 'No, cancel',
    }).then((result) => {
        if (result.isConfirmed) {
            Inertia.post(route('lead-campaigns.disable-lead-for-campaign', {lead: props.lead.id, campaign: campaignId}), {
                only: refetchOnly,
                onSuccess: () => {
                    $notify.success('Lead removed from campaign');
                },
            });
        }
    })
};
</script>

<template>
<div>
  <div class="flex items-center justify-start space-x-2 my-4">
    <el-switch :value="campaignsEnabled" id="campaigns_enabled" @click.prevent="toggleCampaignEnabled" />
    <label class="cursor-pointer text-md" for="campaigns_enabled">Are campaigns enabled for this lead?</label>
  </div>
  <div v-show="campaignsEnabled" class="p-0 md:rounded-lg border ring-1 ring-black ring-opacity-5">
    <table class="min-w-full divide-y divide-gray-300">
      <thead class="bg-slate-200">
      <tr>
        <th scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Name</th>
        <th scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Type</th>
        <th scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Status</th>
        <th scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Is Ongoing</th>
        <th scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Lead Joined At</th>
        <th scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900">Lead Excluded</th>
        <th scope="col" class="whitespace-nowrap px-3 py-3.5 text-sm text-left font-semibold text-gray-900"></th>
      </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
      <tr v-if="!leadCampaigns.length">
        <td class="whitespace-nowrap px-2 py-3.5 text-sm text-gray-900 text-center" colspan="5">This lead is part of no campaigns</td>
      </tr>
      <tr v-else v-for="(leadCampaign, index) in leadCampaigns" :key="leadCampaign.id">
        <td class="whitespace-nowrap px-2 py-3.5 text-sm text-gray-900">{{ leadCampaign.name }}</td>
        <td class="whitespace-nowrap px-2 py-3.5 text-sm text-gray-900">{{ _.upperFirst(leadCampaign.type) }}</td>
        <td class="whitespace-nowrap px-2 py-3.5 text-sm text-gray-900">{{ _.upperFirst(leadCampaign.status) }}</td>
        <td class="whitespace-nowrap px-2 py-3.5 text-sm text-gray-900">{{ leadCampaign.ongoing ? 'Yes' : 'No' }}</td>
        <td class="whitespace-nowrap px-2 py-3.5 text-sm text-gray-900">{{ leadCampaign.joined_at }}</td>
        <td class="whitespace-nowrap px-2 py-3.5 text-sm text-gray-900" :class="{'text-red-500 font-bold': leadCampaign.lead_excluded}">
          {{ leadCampaign.lead_excluded ? 'Yes' : 'No' }}
        </td>
        <td class="whitespace-nowrap px-2 py-3.5 text-sm text-gray-900">
          <div class="flex space-x-4 flex-nowrap">
            <Link :href="getCampaignRoute(leadCampaign)" class="btn btn-primary btn-sm">Go to campaign</Link>
            <button v-if="leadCampaign.ongoing && !leadCampaign.lead_excluded" class="btn text-sm btn-danger" @click="removeCampaign(leadCampaign.id)">Remove from campaign</button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
</template>