<script setup>
import {ref, onMounted, computed} from 'vue';
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";
import {useForm} from "@inertiajs/inertia-vue3";
import axios from "axios";
import {CheckCircleIcon, XIcon, BanIcon} from "@heroicons/vue/solid";
import {ElSwitch} from "element-plus";

const props = defineProps({
    agencyInfo : {
        type     : Object,
        required : true
    },
    sidebar_widgets: {
        type     : Object,
        required : true
    },
});

const formData = useForm({
    hide_leaderboard_nav_item: props.sidebar_widgets.data?.hidden_nav_items?.leaderboard,
    hide_top_10_dashboard_widget: props.sidebar_widgets.data?.dashboard_hidden_widgets?.top_10,
});

const submitForm = () => {
    console.log('submitForm');
    formData.post(route('agencies.sidebar-widgets', {agency: props.agencyInfo.id}), {
        preserveScroll: true,
        onSuccess: () => {
            $notify({
                title: 'Settings saved',
                type: 'success'
            });
        },
        onError: (e) => {
            $notify({
                title: 'Error',
                message: 'There was an error saving the settings: ' + e.message,
                type: 'error'
            });
        }
    });
};
</script>

<template>
<div class="">
  <form @submit.prevent="submitForm">
    <div class="grid grid-cols-12">
      <div class="col-span-12 lg:col-span-3">
        <el-switch v-model="formData.hide_leaderboard_nav_item" id="hide_leaderboard_nav_item" />
        <label for="hide_leaderboard_nav_item" class="ml-2 text-gray-500">Hide <em>Team Standings</em> nav item</label>
      </div>
      <div class="col-span-12 lg:col-span-6">
        <el-switch v-model="formData.hide_top_10_dashboard_widget" id="hide_top_10_dashboard_widget" />
        <label for="hide_top_10_dashboard_widget" class="ml-2 text-gray-500">Hide <em>Top 10</em> dashboard widget</label>
      </div>
    </div>
    <div class="mt-4">
      <button type="submit" class="btn btn-primary">Update</button>
    </div>
  </form>
</div>
</template>

<style scoped>
</style>
